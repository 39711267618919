@import url(https://fonts.googleapis.com/css?family=Assistant&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.recipe-page-container {
  padding: 1.5rem;
  text-align: justify;
}

.recipe-name {
  color: #ff9633;
  font-style: normal;
  font-weight: 800;
  font-size: 2rem;
}

.recipe-wrapper{
  padding:2rem;
}

.ingredients-directions-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.ingredientList {
  width: 30%;
}

.directions {
  width: 50%;
}

.App {
  text-align: center;
}

.recipe-card {
  background-color: #fff;
  border: 0;
  box-shadow: 0 -1px 0 #e0e0e0, 0 0 2px rgba(0, 0, 0, 0.12),
    0 2px 4px rgba(0, 0, 0, 0.24);
  padding: 1rem;
  cursor: pointer;
  position: relative;
  margin: 1rem auto;
  width: 80%;
}


